


















































import {
  get_cxcess_door,
  post_users_add_device,
  post_users_record_fp,
} from "@/api";
import { cxcessDoorType } from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { required } from "@/shared/validation";
import { Notif } from "@/store";
import {
  Component,
  Emit,
  Prop,
  PropSync,
  Vue,
  Watch,
} from "vue-property-decorator";
import DatePicker from "@/components/DatePicker.vue";

type valueType = {
  id: string;
  type: "device" | "fingerprint1" | "fingerprint2";
} | null;
@Component({ name: "users-device", components: { DatePicker } })
export default class UsersDevice extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: valueType;

  @PropSync("typelist", { type: Array }) typeList!: string[];

  /* Variables */

  formData = {
    channelMapping: "",
    type: "",
    expired: "",
  };

  doorList = [] as cxcessDoorType["data"]["_embedded"]["channelMapping"];

  // typeList = [
  //   { value: "card", text: "Card" },
  //   { value: "password", text: "Pin" },
  //   { value: "fingerprint1", text: "Fingerprint 1" },
  //   { value: "fingerprint2", text: "Fingerprint 2" },
  // ];

  date = "";

  loading = false;

  /* Computed */

  public get type(): "device" | "fingerprint1" | "fingerprint2" | null {
    if (!this.value) return null;
    else return this.value.type;
  }

  /* Methods */

  required = required;

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      if (!this.value) return;
      if (this.formData.expired) this.formData.expired += " 23:59:59";
      if (this.type == "device") {
        await post_users_add_device({
          userId: this.value.id,
          ...this.formData,
        });
        Notif.notif_success("Device Successfully Assigned.");
      } else {
        await post_users_record_fp({ userId: this.value.id, ...this.formData });
        Notif.notif_success("Fingerprint Successfully Recorded.");
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  @Emit("input")
  close(): null {
    this.formData = {
      channelMapping: "",
      type: "",
      expired: "",
    };
    return null;
  }

  async fetchDoor(): Promise<void> {
    try {
      const resp = await get_cxcess_door({ limit: -1 });
      this.doorList = resp.data._embedded.channelMapping;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchDoor();
  }

  @Watch("value")
  onValueChange(now: valueType): void {
    if (now) this.formData.type = now?.type;
  }
}
